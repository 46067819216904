import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import HoldemGameSheet from "../../../components/web/HoldemGameSheet";
import {
  checkAlreadyJoinRingRoom,
  createPasswordRingRoom,
  getRingGameList,
  getUserTicketInfo,
  joinRingRoom,
  RingGameItemInterface,
  UserTicketInfoInterface,
} from "../../../api/game";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../recoil/app";
import { LOCAL_STORAGE_ACCESS_KEY } from "../../../utils/network";
import { getKoreanNumber } from "../../../utils/common";
import { MEDIA_DESKTOP } from "../../../hooks/useScreenOrientation";
import useUserInfo from "../../../hooks/useUserInfo";
import { enqueueSnackbar } from "notistack";
import moment from "moment/moment";
import useDialog from "../../../hooks/useDialog";

const RingGameListWrapper = styled.div<{ scrollLock: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 500px;
  height: 100svh;
  overscroll-behavior: none;
  z-index: 10;
  background: white;
  transition: all 0.5s ease-in-out;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${(p) =>
    p.scrollLock
      ? `
      overflow-y: hidden;
  `
      : `
     overflow-y: scroll;
  `} @media ${MEDIA_DESKTOP} {
    position: static;
    top: unset;
    left: unset;
    transform: unset;
    overflow-y: unset;
    max-width: 1200px;
    max-height: unset;
    padding: 0;
    height: auto;
  }

  > .header {
    top: 0;
    position: fixed;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

    > .top {
      position: relative;
      padding: 0 20px;
      width: 100%;
      height: 48px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;

      > .close {
        cursor: pointer;
        width: 24px;
        height: 24px;
      }

      > .title {
        color: ${(p) => p.theme.color.black400};
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      > .button {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        cursor: pointer;
        color: var(--Black-200, #b7b7b7);
        text-align: right;
        font-family: Pretendard;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.26px;
      }
    }

    z-index: 11;
    background: white;
    @media ${MEDIA_DESKTOP} {
      display: none;
    }
  }

  > .inner {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;

    > .wrapper {
      width: 100%;
      @media ${MEDIA_DESKTOP} {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        gap: 42px;
        padding: 0px 70px 80px;
      }

      > .list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 12px;
        padding: 20px 16px 56px;
        @media ${MEDIA_DESKTOP} {
          width: auto;
          gap: 20px;
          padding: 0;
        }

        > .game-info-help-wrapper {
          display: none;
          @media ${MEDIA_DESKTOP} {
            width: 100%;
            display: flex;
            cursor: pointer;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          }
          > .game-info-help {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 2px;
            color: var(--Black-300, #808080);
            font-family: Pretendard;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            > img {
              width: 16px;
              height: 16px;
            }
          }

          > .button {
            position: relative;
            cursor: pointer;
            height: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 2px;
            border-radius: 15px;
            border: 1px solid var(--Purple-200, #8359f7);
            padding: 0 8px;

            > img {
              width: 14px;
              height: 14px;
            }

            > span {
              color: var(--Purple-300, #6436e7);
              font-family: Pretendard;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }

            > .create-popup {
              width: max-content;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: 4px;
              padding: 12px;
              position: absolute;
              left: 0;
              top: 36px;
              z-index: 105;
              border-radius: 8px;
              background: #fff;
              box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);

              > .item {
                width: 100%;
                cursor: pointer;
                padding: 8px 12px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 15px;
                border: 1px solid var(--Black-100, #f0f0f0);
                color: var(--Black-500, #202020);
                font-family: Pretendard;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
`;
const MyPointWrapper = styled.div`
  display: none;
  width: 338px;
  padding: 16px 17px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  border-radius: 12px;
  border: 1px solid var(--Black-100, #f0f0f0);
  @media ${MEDIA_DESKTOP} {
    display: flex;
  }

  > .title {
    color: var(--Black-400, #444);
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.36px;
  }

  > .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > .button {
      cursor: pointer;
      width: 80px;
      height: 36px;
      border-radius: 8px;
      background: var(--Purple-100, #f0eaff);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: var(--Purple-300, #6436e7);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.28px;
    }

    > .info {
      padding: 8px 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 4px;

      > .title {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      > .value {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
`;
const GameInfoItemWrapper = styled.div`
  width: 100%;
  padding: 16px;
  @media ${MEDIA_DESKTOP} {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
    width: fit-content;
  }

  > .pc-wrapper {
    display: none;
    @media ${MEDIA_DESKTOP} {
      display: flex;
    }
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > .game-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;

      > .info {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 8px;
        padding: 8px;
        border-radius: 8px;
        background: var(--Black-100, #f0f0f0);

        > .item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 4px;
          color: var(--Purple-300, #6436e7);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.28px;
        }
      }

      > .button {
        cursor: pointer;
        width: 148px;
        height: 44px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 9px;
        background: var(--Purple-300, #6436e7);
        color: #fff;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    > .info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      > .item {
        width: 230px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        > .sub-title {
          color: var(--Black-300, #808080);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.28px;
        }

        > .title {
          margin-top: 8px;
          color: var(--Black-400, #444);
          font-family: Pretendard;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.4px;
        }

        > .desc {
          margin-top: 4px;
          color: var(--Black-300, #808080);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.28px;
        }
      }
    }
  }

  > .mobile-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
    padding: 16px;
    @media ${MEDIA_DESKTOP} {
      display: none;
    }

    > .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 12px;

      > .row-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 6px;

        > .row {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 6px;

          > .title {
            color: var(--Black-300, #808080);
            font-family: Pretendard;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.26px;
          }

          > .value {
            color: var(--Black-400, #444);
            font-family: Pretendard;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.32px;
          }
        }
      }

      > .info-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;

        > span {
          color: var(--Black-400, #444);
          font-family: Pretendard;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.26px;
        }

        > img {
          width: 16px;
          height: 16px;
        }
      }
    }

    > .button {
      cursor: pointer;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      background: var(--Purple-300, #6436e7);
      height: 30px;
      color: #fff;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    > .button:active {
      background: #502bb5;
    }

    > .button.buyout {
      background: var(--Black-100, #f0f0f0);
      color: var(--Black-400, #444);
    }

    > .button.buyout:active {
      background: var(--Black-200, #e5e5e5);
    }
  }
`;
const MainBanner = styled.div`
  width: 100%;
  height: 188px;
  position: relative;
  @media ${MEDIA_DESKTOP} {
    height: 240px;
  }

  > .background {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  > .title {
    position: absolute;
    bottom: 24px;
    color: #fff;
    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
    font-family: "yg-jalnan";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.4px;
    left: 50%;
    transform: translateX(-50%);
    @media ${MEDIA_DESKTOP} {
      color: #fff;
      text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.8px;
      top: 50%;
      bottom: unset;
      transform: translate(-50%, -50%);
    }
  }
`;
const MobilePointWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 14px 16px;

  > .button {
    position: relative;
    cursor: pointer;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2px;
    border-radius: 15px;
    border: 1px solid var(--Purple-200, #8359f7);
    padding: 0 8px;

    > img {
      width: 14px;
      height: 14px;
    }

    > span {
      color: var(--Purple-300, #6436e7);
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    > .create-popup {
      width: max-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 4px;
      padding: 12px;
      position: absolute;
      left: 0;
      top: 36px;
      z-index: 105;
      border-radius: 8px;
      background: #fff;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);

      > .item {
        width: 100%;
        cursor: pointer;
        padding: 8px 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        border: 1px solid var(--Black-100, #f0f0f0);
        color: var(--Black-500, #202020);
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }

  > .point-wrapper {
    width: 220px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    border: 1px solid var(--Black-200, #b7b7b7);
    padding: 2px 4px 2px 12px;

    > .bar {
      width: 1px;
      height: 10px;
      background: #f0f0f0;
    }

    > .wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;

      > .title {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      > .value {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    > .charge {
      height: 24px;
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: var(--Purple-300, #6436e7);
      text-align: right;
      font-family: Pretendard;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.26px;
      border-radius: 12px;
      background: var(--Purple-100, #f0eaff);
    }
  }

  @media ${MEDIA_DESKTOP} {
    display: none;
  }
`;

interface RingGameList {
  onClose?: () => void;
}

const RingGameListPage = ({ onClose }: RingGameList) => {
  const { user } = useUserInfo();
  const { openDialog } = useDialog();
  const setLoading = useSetRecoilState(loadingState);
  const accessToken = (
    localStorage.getItem(LOCAL_STORAGE_ACCESS_KEY) ?? ""
  ).replace("Bearer ", "");
  const history = useHistory();
  const [userTicketInfo, setUserTicketInfo] =
    useState<UserTicketInfoInterface | null>(null);
  const [showGameInfo, setShowGameInfo] = useState(false);
  const [showCreateSecretRoom, setShowCreateSecretRoom] = useState(false);
  const [showCreateSecretRoomPopup, setShowCreateSecretRoomPopup] =
    useState(false);
  const [showJoinSecretRoomPopup, setShowJoinSecretRoomPopup] = useState(false);
  const handleClose = () => {
    history.replace("/");
  };
  const [gameList, setGameList] = useState<RingGameItemInterface[]>([]);
  useEffect(() => {
    if (accessToken) {
      setLoading(true);
      checkAlreadyJoinRingRoom({
        token: accessToken,
      })
        .then((res) => {
          if (res.data.result === 1) {
            if (res.data.link) {
              window.location.href = res.data.link;
            } else {
              setLoading(true);
              getRingGameList({
                token: accessToken,
              })
                .then((res) => {
                  setGameList(res.data.list.list);
                })
                .finally(() => {
                  setLoading(false);
                });
            }
          } else {
            enqueueSnackbar("정보 로드에 실패했습니다.", { variant: "error" });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      history.push("/login", { redirect: window.location.href });
    }
  }, [accessToken]);

  const handleJoinGame = (gameId: number, password?: string) => {
    setLoading(true);
    joinRingRoom({
      token: accessToken,
      groupId: gameId,
      password: password || "",
    })
      .then((res) => {
        if (res.data.result === 1) {
          window.location.href =
            res.data.link + (password ? "&password=" + password : "");
        } else {
          if (res.data.result === -1) {
            //이미 참여중인 게임이 있을 경우 이동
            checkAlreadyJoinRingRoom({
              token: accessToken,
            }).then((res) => {
              if (res.data.result === 1) {
                if (res.data.link) {
                  openDialog({
                    type: "web",
                    text: "이미 게임에 참여중입니다.<br/>다른 방을 참여하려면 나가기 후 참여가 가능합니다.<br/>이동하시겠습니까?",
                    confirm: true,
                    confirmText: "이동하기",
                    onConfirm: () => {
                      window.location.href = res.data.link!!;
                    },
                  });
                } else {
                  enqueueSnackbar("게임 참여에 실패했습니다.", {
                    variant: "error",
                  });
                }
              } else {
                enqueueSnackbar("정보 로드에 실패했습니다.", {
                  variant: "error",
                });
              }
            });
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (user) {
      getUserTicketInfo({
        userId: user.id,
      })
        .then((res) => {
          if (Object.keys(res.data).length === 0) {
            setUserTicketInfo(null);
            return;
          } else {
            setUserTicketInfo(res.data);
          }
        })
        .catch((e: any) => {});
    }
  }, [user?.id]);

  const handleCreatePasswordGame = () => {
    createPasswordRingRoom({
      token: accessToken,
      bb: 10000,
      password: "1234",
    }).then((res) => {
      console.log("createPasswordRingRoom", res);
    });
  };

  const handleClickCreateSecretRoom = () => {
    openDialog({
      type: "web_create_secret_room",
      onConfirm: () => {
        const BB = (document.getElementById("secret-bb") as HTMLInputElement)
          .value;
        const password = (
          document.getElementById("secret-password") as HTMLInputElement
        ).value;
        if (BB === "" || password === "") {
          openDialog({
            type: "web",
            text: "값을 입력해주세요.",
          });
          return;
        }
        if (parseInt(BB) < 100 || parseInt(BB) > 1000000) {
          openDialog({
            type: "web",
            text: "BB는 100~100만 사이의 값으로 입력해주세요.",
          });
          return;
        }
        if (password.length < 4) {
          openDialog({
            type: "web",
            text: "비밀번호는 4자리 이상으로 입력해주세요.",
          });
          return;
        }
        if (password.length > 20) {
          openDialog({
            type: "web",
            text: "비밀번호는 20자리 이하로 입력해주세요.",
          });
          return;
        }
        if (BB.match(/[^0-9]/)) {
          openDialog({
            type: "web",
            text: "BB는 숫자만 입력해주세요.",
          });
          return;
        }
        createPasswordRingRoom({
          token: accessToken,
          bb: parseInt(BB),
          password: password,
        }).then((res) => {
          openDialog({
            type: "web",
            text:
              "비밀방이 생성되었습니다.<br/><br/>방 번호 : " +
              res.data.groupId +
              "<br/>비밀번호 : " +
              password +
              "<br/><br/>입장하시겠습니까?",
            confirm: true,
            confirmText: "입장하기",
            onConfirm: () => {
              handleJoinGame(res.data.groupId, password);
            },
          });
        });
      },
      onCancel: () => {},
      confirm: true,
      confirmText: "생성하기",
    });
  };
  const handleClickJoinSecretRoom = () => {
    openDialog({
      type: "web_join_secret_room",
      onConfirm: () => {
        handleJoinGame(
          parseInt(
            (document.getElementById("secret-group-id") as HTMLInputElement)
              .value
          ),
          (document.getElementById("secret-password") as HTMLInputElement).value
        );
      },
      onCancel: () => {},
      confirm: true,
      confirmText: "입장하기",
    });
  };

  return (
    <>
      <HoldemGameSheet
        showSheet={showGameInfo}
        setShowSheet={setShowGameInfo}
      />
      <RingGameListWrapper scrollLock={false}>
        <div className="header">
          <div className="top">
            <div className="close" onClick={handleClose}>
              <img src="/image-web/Icon/Back.svg" alt="close" />
            </div>
            <div className="title">홀덤게임 리스트</div>
            <div className="button" onClick={() => setShowGameInfo(true)}>
              홀덤게임 정보
            </div>
          </div>
          <MobilePointWrapper>
            {/*            <div className='button' onClick={() => setShowCreateSecretRoom(!showCreateSecretRoom)}>
              <img src='/image-web/Icon/Secret.svg'/>
              <span>비밀방</span>
              {showCreateSecretRoom && (
                <div className="create-popup">
                  <div className='item' onClick={handleClickCreateSecretRoom}>
                    비밀방 생성
                  </div>
                  <div className='item' onClick={handleClickJoinSecretRoom}>
                    비밀방 입장
                  </div>
                </div>
              )}
            </div>*/}
            <div className="point-wrapper">
              <div className="wrapper">
                <div className="title">보유 GP</div>
                <div className="bar" />
                <div className="value">
                  {" "}
                  {userTicketInfo
                    ? userTicketInfo.user.point.toLocaleString()
                    : "-"}
                </div>
              </div>
            </div>
          </MobilePointWrapper>
        </div>
        <div className="inner">
          <MainBanner>
            <img
              src="/image-web/ringGame/background.png"
              className="background"
            />
            <div className="title">Holdem Game</div>
          </MainBanner>
          <div className="wrapper">
            <div className="list">
              <div className="game-info-help-wrapper">
                <div className="game-info-help">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.5 8.5C13.5 11.5376 11.0376 14 8 14C4.96243 14 2.5 11.5376 2.5 8.5C2.5 5.46243 4.96243 3 8 3C11.0376 3 13.5 5.46243 13.5 8.5ZM8 5.5C7.72386 5.5 7.5 5.72386 7.5 6C7.5 6.27614 7.72386 6.5 8 6.5H8.01C8.28614 6.5 8.51 6.27614 8.51 6C8.51 5.72386 8.28614 5.5 8.01 5.5H8ZM8.5 8.5C8.5 8.22386 8.27614 8 8 8C7.72386 8 7.5 8.22386 7.5 8.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5Z"
                      fill="#808080"
                    />
                  </svg>
                  <span>홀덤 게임 정보</span>
                </div>
                {/*                <div className='button' onClick={() => setShowCreateSecretRoom(!showCreateSecretRoom)}>
                  <img src='/image-web/Icon/Secret.svg'/>
                  <span>비밀방</span>
                  {showCreateSecretRoom && (
                    <div className="create-popup">
                      <div className='item' onClick={handleClickCreateSecretRoom}>
                        비밀방 생성
                      </div>
                      <div className='item' onClick={handleClickJoinSecretRoom}>
                        비밀방 입장
                      </div>
                    </div>
                  )}
                </div>*/}
              </div>
              {gameList.map((item, index) => {
                return (
                  <GameInfoItemWrapper key={index}>
                    <div className="mobile-wrapper">
                      <div className="info">
                        <div className="row-list">
                          <div className="row">
                            <div className="title">SB / BB</div>
                            <div className="value">
                              {getKoreanNumber(item.data.blind[0])} /{" "}
                              {getKoreanNumber(item.data.blind[1])}
                            </div>
                            <div className="title">
                              (앤티 : {getKoreanNumber(item.data.ante)})
                            </div>
                          </div>
                          <div className="row">
                            <div className="title">최소 바이인</div>
                            <div className="value">
                              {getKoreanNumber(item.data.minBuyin)}
                            </div>
                          </div>
                        </div>
                        <div className="info-row">
                          <img src="/image-web/ringGame/UI/List/Icon/Room/small.svg" />
                          <span>테이블</span>
                          <span>{item.tableCount.toLocaleString()}개</span>
                          <span>·</span>
                          <img src="/image-web/ringGame/UI/List/Icon/Person/small.svg" />
                          <span>플레이 인원</span>
                          <span>
                            {Number(item.totalPlayerCount).toLocaleString()}명
                          </span>
                        </div>
                      </div>
                      <div
                        className="button"
                        onClick={() => {
                          handleJoinGame(item.groupId);
                        }}
                      >
                        참여하기
                      </div>
                      {/*    <div
                        className="button buyout"
                        onClick={() => handleJoinGame(item.groupId)}
                      >
                        바이아웃
                      </div>*/}
                    </div>
                    <div className="pc-wrapper">
                      <div className="info">
                        <div className="item">
                          <div className="sub-title">SB / BB</div>
                          <div className="title">
                            {getKoreanNumber(item.data.blind[0])} /{" "}
                            {getKoreanNumber(item.data.blind[1])}
                          </div>
                          <div className="desc">
                            (앤티 : {getKoreanNumber(item.data.ante)})
                          </div>
                        </div>
                        <div className="item">
                          <div className="sub-title">최소 바이인</div>
                          <div className="title">
                            {getKoreanNumber(item.data.minBuyin)}
                          </div>
                        </div>
                      </div>
                      <div className="game-info">
                        <div className="info">
                          <div className="item">
                            <img src="/image-web/Icon/Room/small.svg" />
                            <span>테이블</span>
                            <span>{item.tableCount.toLocaleString()}개</span>
                          </div>
                          <div className="item">
                            <img src="/image-web/Icon/Person/small.svg" />
                            <span>플레이 인원</span>
                            <span>
                              {Number(item.totalPlayerCount).toLocaleString()}명
                            </span>
                          </div>
                        </div>
                        <div
                          className="button"
                          onClick={() => handleJoinGame(item.groupId)}
                        >
                          참여하기
                        </div>
                        {/*      <div
                          className="button buyout"
                          onClick={() => handleJoinGame(item.groupId)}
                        >
                          바이아웃
                        </div>*/}
                      </div>
                    </div>
                  </GameInfoItemWrapper>
                );
              })}
            </div>
            <MyPointWrapper>
              <div className="title">내 포인트</div>
              <div className="row">
                <div className="info">
                  <div className="title">보유 GP</div>
                  <div className="value">
                    {userTicketInfo
                      ? userTicketInfo.user.point.toLocaleString()
                      : "-"}
                  </div>
                </div>
              </div>
            </MyPointWrapper>
          </div>
        </div>
      </RingGameListWrapper>
    </>
  );
};

export default RingGameListPage;
