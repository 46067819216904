import Footer from "../../../components/web/Footer";
import styled from "styled-components";

import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import HoldemNow from "./HoldemNow";
import RecommendPub from "./RecommendPub";
import PremiumPub from "./PremiumPub";
import NearPub from "./NearPub";
import MajorTournament from "./MajorTournament";
import EventPub from "./EventPub";
import AppBanner from "./AppBanner";
import useGeoLocation from "../../../hooks/useGeoLocation";
import { dashboard, DashboardResponse } from "../../../api/dashboard";
import TournamentTimer from "./TournamentTimer";
import { enqueueSnackbar } from "notistack";
import { openNewWindow, openUrl } from "../../../utils/common";
import { getTournamentInfo, TournamentInfoInterface } from "../../../api/game";
import { MEDIA_DESKTOP } from "../../../hooks/useScreenOrientation";
import useDialog from "../../../hooks/useDialog";
import { Autoplay } from "swiper/modules";
import { getPopupNoticeList, PopupNoticeData } from "../../../api/notice";
import PopupNotice from "../../../components/common/PopupNotice";
import { LOCAL_STORAGE_ACCESS_KEY } from "../../../utils/network";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userState } from "../../../recoil/auth";
import YoutubeList from "./Youtube";
import useNativeApp, { isApp } from "../../../hooks/useNativeApp";
import { BasicInfo, getAppUpdate } from "../../../api/app-update";
import { scheduleState } from "../../../recoil/schedule";

const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  position: relative;

  > .inner {
    width: 100%;
    @media ${MEDIA_DESKTOP} {
      max-width: 1060px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    > .ring-wrapper {
      @media ${MEDIA_DESKTOP} {
        display: none;
      }
      padding: 0 16px;
      width: 100%;
      margin-top: 10px;
      position: relative;

      > img {
        width: 100%;
      }
      > .button {
        display: flex;
        width: calc(100% - 64px);
        height: 44px;
        justify-content: center;
        align-items: center;
        color: #fff;
        text-align: center;
        font-family: Pretendard;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.165px;
        border-radius: 8px;
        background: var(--Purple-300, #6436e7);
        position: absolute;
        right: 32px;
        bottom: 16px;
      }

      > .box {
        position: absolute;
        left: 32px;
        top: 26px;
        > .title {
          color: #fff;
          font-family: Pretendard;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.36px;
        }

        > .description {
          margin-top: 6px;
          color: #fff;
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.36px;
        }
      }
    }
    .banner-swiper-wrapper {
      width: 100%;
      aspect-ratio: 360/200;
      position: relative;
      @media ${MEDIA_DESKTOP} {
        aspect-ratio: 1200/360;
      }

      > .slide-index {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
        position: absolute;
        right: 17.5px;
        bottom: 68px;
        z-index: 10;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 20px;
        padding: 0 8px;
        color: ${(p) => p.theme.color.black200};
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.24px;
        gap: 3px;
        @media ${MEDIA_DESKTOP} {
          bottom: 30px;
          right: 30px;
        }

        > .current {
          color: white;
        }
      }

      > .search-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 11px;
        position: absolute;
        bottom: 8%; // 20px
        left: 50%;
        z-index: 2;
        transform: translateX(-50%);
        width: calc(100% - 56px);
        height: 13%; // 36px
        border-radius: 18px;
        background: #fff;
        cursor: pointer;
        color: ${(p) => p.theme.color.black200};
        text-align: center;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 20px 0px;
        @media ${MEDIA_DESKTOP} {
          display: none;
        }
      }
    }

    .banner-swiper {
      width: 100%;
      aspect-ratio: 360/200;
      @media ${MEDIA_DESKTOP} {
        aspect-ratio: 1200/360;
      }
    }
  }
`;

const BannerWrapper = styled.div<{ bg?: string }>`
  width: 100%;
  aspect-ratio: 360/200;
  background-color: ${(p) => p.bg || "#ffffff"};
  @media ${MEDIA_DESKTOP} {
    aspect-ratio: 1200/360;
  }
  > .banner {
    width: 100%;
    aspect-ratio: 360/144;
    @media ${MEDIA_DESKTOP} {
      aspect-ratio: 1200/360;
      object-fit: contain;
    }
  }
`;

const TournamentWrapper = styled.div`
  margin-top: 42px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  @media ${MEDIA_DESKTOP} {
    flex-direction: row;
  }

  > .item {
    padding: 0 16px;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    cursor: pointer;
    width: 100%;
    height: 68px;
    flex-shrink: 0;
    position: relative;
    @media ${MEDIA_DESKTOP} {
      width: 520px;
      height: 84px;
      flex-shrink: 0;
      border-radius: 12px;
      background: #fff;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
    }

    > .entry {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 8px;
      position: absolute;
      top: -12px;
      left: 12px;
      width: fit-content;
      height: 24px;
      color: ${(p) => p.theme.color.purple100};
      text-align: center;
      font-family: Pretendard;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 22px;
      background: ${(p) => p.theme.color.purple400};
      @media ${MEDIA_DESKTOP} {
        display: none;
      }
    }

    > .desc {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 5px;
      @media ${MEDIA_DESKTOP} {
        gap: 8px;
        justify-content: flex-start;
      }

      > .icon {
        width: 24px;
        height: 24px;
      }

      > .title {
        flex-grow: 1;
        color: ${(p) => p.theme.color.black500};
        text-align: center;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.32px;
        word-break: keep-all;
      }
    }

    > .time-wrapper {
      padding-left: 12px;
      border-left: 1px solid ${(p) => p.theme.color.black100};
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media ${MEDIA_DESKTOP} {
        display: none;
      }
      > .title {
        color: ${(p) => p.theme.color.purple300};
        text-align: center;
        font-family: Pretendard;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      > .time {
        color: ${(p) => p.theme.color.purple300};
        text-align: center;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.64px;
      }
    }
    > .pc-item {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 3px;
      display: none;
      flex-shrink: 0;
      width: 140px;
      border-left: 1px solid ${(p) => p.theme.color.black100};
      @media ${MEDIA_DESKTOP} {
        display: flex;
      }
      > .title {
        color: var(--Purple-300, #6436e7);
        text-align: center;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      > .value {
        color: var(--Purple-300, #6436e7);
        text-align: center;
        font-family: Pretendard;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.8px;
      }
      > .time {
        color: var(--Purple-300, #6436e7);
        text-align: center;
        font-family: Pretendard;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.8px;
      }
    }
  }
`;
const HotKeyMenuWrapper = styled.div`
  width: 100%;
  padding: 30px 16px 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > .menus {
    padding: 0 8px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 16px;
    justify-items: center;
    justify-content: center;
    @media ${MEDIA_DESKTOP} {
      display: none;
    }
    > .item {
      width: 80px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: background-color 0.2s ease-in-out;
      position: relative;

      &:active {
        background-color: #f0f0f0;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }

      > .icon {
        width: 40px;
        height: 40px;
        object-fit: contain;
      }

      > .title {
        margin-top: 5px;
        color: black;
        text-align: center;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }

      > .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 80%;
        // background: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: bold;
        border: none;
        font-size: 15px;

        > .ribbon {
          border-radius: 5px;
          width: 75%;
          height: 18px;
          background: red;
          color: white;
          text-align: center;
        }
      }
    }
  }
`;
const Home = () => {
  const { openDialog } = useDialog();
  const history = useHistory();
  const [currenBannerIndex, setCurrentBannerIndex] = useState(0);
  const [dashboardData, setDashboardData] = useState<DashboardResponse>();
  const [tournamentData, setTournamentData] = useState<
    TournamentInfoInterface[]
  >([]);
  const { latitude, longitude } = useGeoLocation();
  const [popupNoticeData, setPopupNoticeData] = useState<PopupNoticeData[]>([]);
  const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_KEY);
  const user = useRecoilValue(userState);
  const { sendMessageToNative } = useNativeApp();
  const setSchedule = useSetRecoilState(scheduleState);

  const isAdult = (birthdate: string) => {
    const birthDate = new Date(birthdate);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age >= 18;
  };
  const compareVersions = (
    currentVersion: string,
    latestVersion: string
  ): boolean => {
    const versionTuple = (version: string): number[] => {
      return version.split(".").map((num) => parseInt(num, 10));
    };

    const currentVersionTuple = versionTuple(currentVersion);
    const latestVersionTuple = versionTuple(latestVersion);

    for (
      let i = 0;
      i < Math.max(currentVersionTuple.length, latestVersionTuple.length);
      i++
    ) {
      const current = currentVersionTuple[i] || 0;
      const latest = latestVersionTuple[i] || 0;

      if (current < latest) {
        return true;
      } else if (current > latest) {
        return false;
      }
    }

    return false; // Versions are the same
  };
  useEffect(() => {
    if (isApp) {
      sendMessageToNative("basicInfo", "basicInfoCallback", {}).then(
        async (res: any) => {
          if (!res) {
            return;
          }
          const updateData = await getAppUpdate();
          const androidRequired = updateData.find((e) => e.os === "android");
          const iosRequired = updateData.find((e) => e.os === "ios");
          if (res.os === "android" && androidRequired) {
            const valid = compareVersions(
              res.version,
              androidRequired?.version
            );
            if (valid) {
              openDialog({
                title: "앱 업데이트",
                text: "최신 버전으로 업데이트를 해주세요",
                confirm: !androidRequired.update,
                confirmText: "업데이트",
                type: "app-update",
                disableAutoClose: androidRequired.update,
                onConfirm: () => {
                  const url = `https://play.google.com/store/apps/details?id=${androidRequired.appId}`;
                  openNewWindow(url);
                },
              });
            }
          } else {
            if (!iosRequired) {
              return;
            }
            const valid = compareVersions(res.version, iosRequired.version);
            if (valid) {
              openDialog({
                title: "앱 업데이트",
                text: "최신 버전으로 업데이트를 해주세요",
                confirm: !iosRequired.update,
                confirmText: "업데이트",
                type: "app-update",
                disableAutoClose: iosRequired.update,
                onConfirm: () => {
                  const url = `https://apps.apple.com/app/${iosRequired.appId}`;
                  openNewWindow(url);
                },
              });
            }
          }
        }
      );
    }
  }, []);
  useEffect(() => {
    dashboard({
      lat: latitude,
      lon: longitude,
    })
      .then((res) => {
        setDashboardData(res);
      })
      .catch((e) => {
        enqueueSnackbar("데이터를 불러올 수 없습니다: " + e.message, {
          variant: "error",
        });
      });
    getPopupNoticeList().then((res) => {
      const filteredData = res.filter((x) => {
        //localStorage.setItem(`popup_notice_${data.id}`, new Date().toISOString());
        //위 코드는 오늘하루 보지 않기 처리
        //오늘 하루 보지않기 처리된것들 제외
        const prevData = localStorage.getItem(`popup_notice_${x.id}`);
        if (!prevData) {
          return true;
        } else {
          const prevDate = new Date(prevData);
          const now = new Date();
          return now.getDate() !== prevDate.getDate();
        }
      });
      setPopupNoticeData(filteredData);
    });
  }, [latitude, longitude]);
  useEffect(() => {
    if (dashboardData) {
      dashboardData.tournament.map((item) => {
        getTournamentInfo({
          groupId: item.groupId,
          token: "-",
        })
          .then((res) => {
            if (res.data && JSON.stringify(res.data) !== "{}") {
              setTournamentData((p) => [...p, res.data]);
            }
          })
          .catch((e: any) => {
            // TODO: handle error
          });
      });
    }
  }, [dashboardData]);
  const handleSearch = () => {
    history.replace("/search", { mode: "query" });
  };

  return (
    <MainWrapper>
      {popupNoticeData.length > 0 && (
        <PopupNotice
          data={popupNoticeData[0]}
          onClose={() => {
            setPopupNoticeData(popupNoticeData.slice(1));
          }}
        />
      )}
      <div className="inner">
        {dashboardData && (
          <div className="banner-swiper-wrapper">
            <Swiper
              className="banner-swiper"
              onRealIndexChange={(swiper: any) =>
                setCurrentBannerIndex(swiper.realIndex || 0)
              }
              loop={
                dashboardData?.runnerEventBanner &&
                dashboardData?.runnerEventBanner.length > 1
              }
              autoplay={{ delay: 2000 }}
              modules={[Autoplay]}
            >
              {dashboardData?.runnerEventBanner.map((item, i) => (
                <SwiperSlide key={i}>
                  <BannerWrapper
                    onClick={() => history.push(`/event/detail/${item.id}`)}
                    bg={item.backgroundColor}
                  >
                    <img src={item.url} alt="banner" className="banner" />
                  </BannerWrapper>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="slide-index">
              <span className="current">{currenBannerIndex + 1}</span>
              <span>/</span>
              <span>{dashboardData?.runnerEventBanner.length || "-"}</span>
            </div>
            <div className="search-wrapper" onClick={handleSearch}>
              <span>원하는 홀덤 펍을 검색해보세요</span>
              <img
                className="icon"
                src="/image-web/Icon/Search/small.svg"
                alt="search-icon"
              />
            </div>
          </div>
        )}
        <HotKeyMenuWrapper>
          <div className="menus">
            {[
              {
                icon: "/image-web/Hot_Icon/내주변.png",
                title: "내 주변",
                onClick: () => history.push("/search"),
                process: true,
              },
              {
                icon: "/image-web/Hot_Icon/지역별.png",
                title: "지역별",
                onClick: () => history.push("/search", { mode: "area" }),
                process: true,
              },
              {
                icon: "/image-web/Hot_Icon/홀덤뉴스.png",
                title: "뉴스 & 스케줄",
                process: false,
                onClick: () => {
                  history.push("/news");
                },
              },
              {
                icon: "/image-web/Hot_Icon/진행이벤트.png",
                title: "진행이벤트",
                onClick: () => history.push("/event/list"),
                process: true,
              },
              {
                icon: "/image-web/Hot_Icon/러너게임랭킹.png",
                title: "러너 랭킹",
                onClick: () => history.push("/ranking"),
                process: true,
              },
              {
                icon: "/image-web/Hot_Icon/홀덤가이드.png",
                title: "홀덤 가이드",
                onClick: () => history.push("/holdem/guide"),
                process: true,
              },
              {
                icon: "/image-web/Hot_Icon/지역별 오픈채팅.png",
                title: "지역별\n오픈채팅",
                onClick: () => history.push("/openChat"),
                process: true,
              },
              {
                icon: "/image-web/Hot_Icon/러튜브.png",
                title: "러튜브",
                onClick: () => history.push("/youtube"),
                process: true,
              },
              {
                icon: "/image-web/Hot_Icon/실시간 채용.png",
                title: "실시간 채용",
                onClick: () => history.push("/recruit"),
                process: true,
              },
              {
                icon: "/image-web/Hot_Icon/홀덤게임 계산기.png",
                title: "홀덤확률계산기",
                process: true,
                onClick: () => {
                  history.push("/calc");
                },
              },
              {
                icon: "/image-web/Hot_Icon/중고장터.png",
                title: "중고장터",
                process: false,
                onClick: () => {
                  history.push("/second-hand-market");
                },
              },
              {
                icon: "/image-web/Hot_Icon/홀덤게임.png",
                title: "홀덤 게임",
                process: false,
                onClick: () => {
                  if (accessToken) {
                    if (user?.birthday && !isAdult(user?.birthday)) {
                      enqueueSnackbar(
                        "성인인증을 받은 회원만 참여하실 수 있습니다.",
                        {
                          variant: "error",
                        }
                      );
                      return;
                    }

                    if (user?.validate) {
                      history.push(`/game/ring/list`);
                    } else {
                      enqueueSnackbar("본인인증이 필요한 서비스입니다.", {
                        variant: "error",
                      });
                      history.push("/mypage?menu=profile");
                    }
                  } else {
                    enqueueSnackbar("로그인이 필요합니다.", {
                      variant: "error",
                    });
                    history.push(`/login`);
                  }
                },
              },
              // {
              //   icon: "/image-web/Hot_Icon/나의 홀덤일지.png",
              //   title: "나의 홀덤일지",
              //   process: false,
              //   onClick: () => {
              //     openDialog({
              //       title: "나의 홀덤일지",
              //       type: "web",
              //       text: "🚧나의 홀덤일지는 현재 준비 중입니다🚧<br><br>준비가 완료되는 대로 공지를 통해<br>알려드리겠습니다.",
              //       style: {
              //         display: "flex",
              //         justifyContent: "center",
              //         alignItems: "center",
              //         height: "100%",
              //       },
              //     });
              //   },
              // },
            ].map((item, index) => (
              <div
                className="item"
                key={index}
                onClick={item.onClick}
                id={item.title}
              >
                <img src={item.icon} alt="icon" className="icon" />
                <span className="title">{item.title}</span>
              </div>
            ))}
          </div>
          <TournamentWrapper>
            {dashboardData?.tournament
              .sort((a, b) => {
                //tournamentGroup: H가 우선
                if (a.tournamentGroup === "H" && b.tournamentGroup !== "H") {
                  return -1;
                } else if (
                  a.tournamentGroup !== "H" &&
                  b.tournamentGroup === "H"
                ) {
                  return 1;
                }
                return 0;
              })
              .map((item) => {
                let icon;
                if (item.tournamentGroup === "H") {
                  icon = "/image-web/Heart%20Suit.png";
                } else if (item.tournamentGroup === "S") {
                  icon = "/image-web/Spade%20Suit.png";
                }

                return tournamentData.find(
                  (x) => x.info.groupId === item.groupId
                ) ? (
                  <div
                    className="item"
                    key={item.id}
                    onClick={() => {
                      if (accessToken) {
                        if (user?.birthday && !isAdult(user?.birthday)) {
                          enqueueSnackbar(
                            "성인인증을 받은 회원만 참여하실 수 있습니다.",
                            {
                              variant: "error",
                            }
                          );
                          return;
                        }
                        if (user?.validate) {
                          history.push(
                            `/event/tournament/detail/${item.groupId}`
                          );
                        } else {
                          enqueueSnackbar("본인인증이 필요한 서비스입니다.", {
                            variant: "error",
                          });
                          history.push("/mypage?menu=profile");
                        }
                      } else {
                        enqueueSnackbar("로그인이 필요합니다.", {
                          variant: "error",
                        });
                        history.push(`/login`);
                      }
                    }}
                  >
                    <div className="entry">
                      엔트리{" "}
                      {tournamentData.find(
                        (x) => x.info.groupId === item.groupId
                      )?.info.totalRegisterCount ?? "-"}
                      명 등록
                    </div>
                    <div className="desc">
                      <img className="icon" src={icon} />
                      <div className="title">{item.title}</div>
                    </div>
                    <div className="time-wrapper">
                      <TournamentTimer
                        start={item.startAt}
                        end={
                          tournamentData.find(
                            (x) => x.info.groupId === item.groupId
                          )?.info.isEnd
                        }
                      />
                    </div>
                    <div className="pc-item">
                      <div className="title">앤트리 등록</div>
                      <div className="value">
                        {tournamentData.find(
                          (x) => x.info.groupId === item.groupId
                        )?.info.totalRegisterCount ?? "-"}
                      </div>
                    </div>
                    <div className="pc-item">
                      <TournamentTimer
                        start={item.startAt}
                        end={
                          tournamentData.find(
                            (x) => x.info.groupId === item.groupId
                          )?.info.isEnd
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                );
              })}
          </TournamentWrapper>
        </HotKeyMenuWrapper>
        <div className="ring-wrapper">
          <img src="/image-web/ringGame/ring-banner.png" />
          <div
            className="button"
            onClick={() => {
              if (accessToken) {
                if (user?.birthday && !isAdult(user?.birthday)) {
                  enqueueSnackbar(
                    "성인인증을 받은 회원만 참여하실 수 있습니다.",
                    {
                      variant: "error",
                    }
                  );
                  return;
                }

                if (user?.validate) {
                  history.push(`/game/ring/list`);
                } else {
                  enqueueSnackbar("본인인증이 필요한 서비스입니다.", {
                    variant: "error",
                  });
                  history.push("/mypage?menu=profile");
                }
              } else {
                enqueueSnackbar("로그인이 필요합니다.", {
                  variant: "error",
                });
                history.push(`/login`);
              }
            }}
          >
            게임 입장
          </div>
          <div className="box">
            <div className="title">
              HOLDEM <br />
              GAME
            </div>
            <div className="description">신규모드 언제 어디서든 즐기자 !</div>
          </div>
        </div>
        <PremiumPub list={dashboardData?.premium ?? []} />
        <NearPub
          list={dashboardData?.around ?? []}
          city={dashboardData?.city}
        />
        <MajorTournament list={dashboardData?.majorTournament ?? []} />
        <YoutubeList list={dashboardData?.youtube ?? []} />
        <EventPub list={dashboardData?.event ?? []} />
        <AppBanner />
      </div>
      <Footer />
    </MainWrapper>
  );
};

export default Home;
